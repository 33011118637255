import React from "react"
import Layout from "../components/layout"
import { charte } from "../components/charte"
import { Document, Page } from 'react-pdf';
import CR1 from "../meetings-pdf/GC_CR1_2019_06_28.pdf";
import CR2 from "../meetings-pdf/GC CR2 2019_10_10.pdf";
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"

const MeetingColumn = (props) => {
  return ( <>
    <div align="center" style={{color: charte.secondary}}>{props.download.slice(20,30)}</div>
    <a href={props.download}><Document file={props.file}>
    <Page pageNumber={1} scale={0.5}/>
      </Document></a></>

)
}

const Meetings = () => (
  <Layout page={"Meetings"}>
    <h1 align="center" style={{ color: charte.primary }}> To access our meeting reports, please send us an email at greencom.cirb [at] listes.college-de-france [dot] fr</h1>
  </Layout>
)


export default Meetings